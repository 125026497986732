<template>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Cantata+One&display=swap");

.font-logo * {
  font-family: "Cantata One", serif !important;
}

.logo {
  font-family: "Cantata One", serif !important;
  cursor: pointer;
}

a.logo {
  text-decoration: none;
  color: inherit;
}

.select-none {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
}

/* make scrollbar pretty */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}
</style>

<script>
export default {
  name: "App",
};
</script>
