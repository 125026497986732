import { loadDraftFromLocalStorage, onNavigation } from "./utils";

import { auth, firestore } from "@/firebase/app";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
} from "@firebase/firestore";

export default {
  path: "/",
  component: () => import("@/views/Home"),
  children: [
    {
      path: "/u/:userId",
      component: () => import("@/views/Home"),
      meta: {
        ref: (route) => doc(firestore, "users", route.params.userId),
      },
      children: [
        {
          path: "d/:draftId",
          name: "user/drafts/get/public",
          component: () => import("@/views/DraftPublic"),
        },
      ],
    },
    {
      path: "/me",
      alias: "/me/d",
      component: () => import("@/views/Home"),
      meta: {
        authRequired: true,
        ref: () => doc(firestore, "users", auth.currentUser.uid),
        routes: () => ({
          listDrafts: { name: "user/drafts/list" },
          newDraft: { name: "user/drafts/new" },
          copyDraft: (draftId) => ({
            name: "user/drafts/copy",
            params: { draftId },
          }),
          getDraft: (draftId) => ({
            name: "user/drafts/get",
            params: { draftId },
          }),
          getPublicDraft: (draftId) => ({
            name: "user/drafts/get/public",
            params: {
              userId: auth.currentUser.uid,
              draftId,
            },
          }),
        }),
      },
      children: [
        {
          path: "",
          name: "user",
          beforeEnter: onNavigation(async (to, from, next) => {
            const template = window.localStorage?.getItem("draft/template");
            if (template) {
              return next({ name: "user/drafts/new" });
            }
            const ref = to.matched.find((r) => r.meta.ref).meta.ref();
            const result = await getDocs(
              query(
                collection(ref, "drafts"),
                orderBy("viewed", "desc"),
                limit(1)
              )
            );
            if (result.empty) {
              return next({ name: "user/drafts/new" });
            }
            return next({
              name: "user/drafts/get",
              params: { draftId: result.docs[0].id },
            });
          }),
        },
        {
          path: "drafts",
          name: "user/drafts/list",
          component: () => import("@/views/DraftList"),
        },
        {
          path: "drafts/new",
          name: "user/drafts/new",
          beforeEnter: onNavigation(async (to, from, next) => {
            const ref = to.matched.find((r) => r.meta.ref).meta.ref();
            const now = new Date();
            const draft = {
              ...loadDraftFromLocalStorage(),
              country: "usa",
              created: now,
              updated: now,
            };
            const result = await addDoc(collection(ref, "drafts"), draft);
            window.localStorage?.removeItem("draft/title");
            window.localStorage?.removeItem("draft/template");
            return next({
              name: "user/drafts/get",
              params: { draftId: result.id },
            });
          }),
        },
        {
          path: "drafts/copy/:draftId",
          name: "user/drafts/copy",
          beforeEnter: onNavigation(async (to, from, next) => {
            const ref = to.matched.find((r) => r.meta.ref).meta.ref();
            const d = await getDoc(doc(ref, "drafts", to.params.draftId));
            if (!d.exists()) {
              return next({ name: "404", params: [to.path] });
            }
            const draft = d.data();
            const now = new Date();
            draft.title = `Copy of ${d.get("title")}`;
            draft.created = now;
            draft.updated = now;
            delete draft.viewed;
            delete draft.isPublic;
            const result = await addDoc(collection(ref, "drafts"), draft);
            return next({
              name: "user/drafts/get",
              params: { draftId: result.id },
            });
          }),
        },
        {
          path: "d/:draftId",
          name: "user/drafts/get",
          component: () => import("@/views/DraftAuth"),
          redirect: { name: "user/drafts/get?view=times" },
          meta: {
            public: (route) => ({
              name: "user/drafts/get/public",
              params: {
                userId: auth.currentUser.uid,
                draftId: route.params.draftId,
              },
            }),
          },
          children: [
            {
              path: "times",
              name: "user/drafts/get?view=times",
              component: () => import("@/views/TimesDraft"),
              props: {
                minBoxWidth: 80,
                minBoxHeight: 40,
              },
              meta: {
                view: "times",
              },
            },
            {
              path: "staff",
              name: "user/drafts/get?view=staff",
              component: () => import("@/views/StaffDraft"),
              props: (route) => ({
                week: parseInt(route.query.week ?? -1),
                withRange: true,
                minBoxWidth: 80,
                minBoxHeight: 60,
                maxBoxHeight: 80,
              }),
              meta: {
                view: "staff",
              },
            },
          ],
        },
        {
          path: "settings",
          name: "user/settings",
          component: () => import("@/views/Settings"),
          meta: {
            title: "Settings - Take Initiative",
          },
        },
        {
          path: "settings/export",
          name: "user/export",
          component: () => import("@/views/Export"),
          meta: {
            title: "Take Initiative",
          },
        },
      ],
    },
  ],
};
