export default {
  namespaced: true,
  state: {
    display: false,
    level: "",
    text: "",
  },
  getters: {
    display(state) {
      return state.display;
    },
    level(state) {
      return state.level;
    },
    text(state) {
      return state.text;
    },
  },
  mutations: {
    setDisplay(state, value) {
      state.display = value;
    },
    setAlert(state, { level, text }) {
      state.display = true;
      state.level = level;
      state.text = text;
    },
  },
  actions: {
    setDisplay({ commit }, value = false) {
      commit("setDisplay", value);
    },
    info({ commit }, text) {
      commit("setAlert", { level: "info", text });
    },
    success({ commit }, text) {
      commit("setAlert", { level: "success", text });
    },
    error({ commit }, text) {
      commit("setAlert", { level: "error", text });
    },
    warn({ commit }, text) {
      commit("setAlert", { level: "warn", text });
    },
  },
};
