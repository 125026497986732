export default {
  path: "/auth",
  component: () => import("@/views/Auth"),
  redirect: { name: "SignIn" },
  props: (route) => ({
    labels: {
      action: `auth.${route.meta.prefix}.action`,
      question: `auth.${route.meta.prefix}.question`,
      answer: `auth.${route.meta.prefix}.answer`,
      submit: `auth.${route.meta.prefix}.submit`,
    },
    alt: route.meta.alt,
  }),
  meta: {
    skipWithAuth: true,
  },
  children: [
    {
      path: "sign-up",
      name: "SignUp",
      meta: {
        title: "Create a Take Initiative account",
        prefix: "sign-up",
        alt: { name: "SignIn" },
      },
    },
    {
      path: "sign-in",
      name: "SignIn",
      meta: {
        title: "Welcome back to Take Initiative",
        prefix: "sign-in",
        alt: { name: "SignUp" },
      },
    },
    {
      path: "sign-in",
      name: "SignOut",
      meta: {
        title: "You have been signed out of Take Initiative",
        prefix: "sign-in",
        alt: { name: "SignUp" },
      },
    },
  ],
};
