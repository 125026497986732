import "./polyfills";

import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import i18n from "./i18n";
import router from "./router";
import store from "./store";
import firebase from "./firebase/plugin";
import "./stripe";

Vue.config.productionTip = false;

// FIXME: Try this again in Vue3 (https://github.com/vuejs/vue/issues/10009)
// Vue.config.errorHandler = (err, vm, info) => {
//     vm.$firebase.log('error', {
//         page_location: vm.$route.fullPath,
//         page_path: vm.$route.path,
//         err,
//         info
//     })
// }

let unsubscribe;

new Vue({
  vuetify,
  i18n,
  router,
  store,
  firebase,
  render: (h) => h(App),
  created() {
    // Load the configuration
    const assetsEndpoint = process.env.VUE_APP_ASSETS_ENDPOINT;
    const assetsHeaders = new Headers({});
    if (process.env.VUE_APP_ASSETS_SHA)
      assetsHeaders.set("TakeInit-SHA", process.env.VUE_APP_ASSETS_SHA);

    fetch(assetsEndpoint, { headers: assetsHeaders })
      .then((response) => response.json())
      .then((value) => this.$store.dispatch("config/setData", value))
      .catch((err) => console.error("Could not load site configuration", err));
    unsubscribe = this.$firebase.onAuthStateChanged(
      (user) => this.$store.dispatch("user/setData", user),
      (err) => {
        throw err;
      }
    );
  },
  destroyed() {
    if (unsubscribe) unsubscribe();
    unsubscribe = null;
  },
  errorCaptured(err, vm, info) {
    vm.$store.dispatch("alert/error", err.message);
    vm.$firebase.log("error", {
      page_location: vm.$route.fullPath,
      page_path: vm.$route.path,
      err,
      info,
    });
  },
}).$mount("#app");
