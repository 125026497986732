export default {
  namespaced: true,
  state: {
    data: null,
    claims: null,
  },
  getters: {
    isLoggedIn(state) {
      return state.data !== null;
    },
    data(state) {
      return state.data;
    },
    uid(state) {
      return state.data?.uid;
    },
    displayName(state) {
      return state.data?.displayName ?? "";
    },
    email(state) {
      return state.data?.email;
    },
    photoURL(state) {
      return state.data?.photoURL;
    },
    providers(state) {
      return state.data?.providerData;
    },
    googleProvider(state) {
      return state.data?.providerData.find(
        (p) => p.providerId === "google.com"
      );
    },
    roles(state) {
      return state.claims?.roles ?? {};
    },
  },
  mutations: {
    setData(state, value) {
      state.data = value;
    },
    setClaims(state, value) {
      state.claims = value;
    },
  },
  actions: {
    setData({ commit }, user) {
      if (!user) {
        commit("setData", null);
        return;
      }
      commit("setData", {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        providerData: user.providerData ?? [],
      });
    },
    setClaims({ commit }, claims) {
      if (!claims) {
        commit("setClaims", null);
        return;
      }
      commit("setClaims", claims);
    },
  },
};
