import { initializeApp } from "@firebase/app";
import { getAnalytics } from "@firebase/analytics";
import { getAuth, connectAuthEmulator } from "@firebase/auth";
import { getStorage, connectStorageEmulator } from "@firebase/storage";
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";

const app = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || process.env.NODE_ENV,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});

const options = {};
if (process.env.VUE_APP_FIREBASE_EMULATOR_ENABLED === "true") {
  options.auth = {
    useEmulator: true,
    emulatorHost: process.env.VUE_APP_FIREBASE_AUTH_EMULATOR_HOST,
    emulatorPort: parseInt(process.env.VUE_APP_FIREBASE_AUTH_EMULATOR_PORT),
  };
  options.storage = {
    useEmulator: true,
    emulatorHost: process.env.VUE_APP_FIREBASE_STORAGE_EMULATOR_HOST,
    emulatorPort: parseInt(process.env.VUE_APP_FIREBASE_STORAGE_EMULATOR_PORT),
  };
  options.firestore = {
    useEmulator: true,
    emulatorHost: process.env.VUE_APP_FIRESTORE_EMULATOR_HOST,
    emulatorPort: parseInt(process.env.VUE_APP_FIRESTORE_EMULATOR_PORT),
  };
}

const analytics = getAnalytics(app);

const auth = getAuth(app);
if (options.auth?.useEmulator) {
  console.warn("Connecting to the auth emulator", options.auth);
  connectAuthEmulator(
    auth,
    `http://${options.auth.emulatorHost}:${options.auth.emulatorPort}`,
    { disableWarnings: true }
  );
}

const storage = getStorage(app);
if (options.storage?.useEmulator) {
  console.warn("Connecting to the storage emulator", options.storage);
  connectStorageEmulator(
    storage,
    options.storage.emulatorHost,
    options.storage.emulatorPort
  );
}

const firestore = getFirestore(app);
if (options.firestore?.useEmulator) {
  console.warn("Connecting to the firestore emulator", options.firestore);
  connectFirestoreEmulator(
    firestore,
    options.firestore.emulatorHost,
    options.firestore.emulatorPort
  );
}

export { analytics, auth, firestore, storage };
