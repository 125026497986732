const left = [
  "adamant",
  "admiring",
  "adoring",
  "affectionate",
  "amazing",
  "amped",
  "awesome",
  "beautiful",
  "blissful",
  "bodacious",
  "bold",
  "brave",
  "busy",
  "calm",
  "captivating",
  "charming",
  "clever",
  "cool",
  "compassionate",
  "competent",
  "confident",
  "dazzling",
  "delighted",
  "determined",
  "distinguished",
  "eager",
  "ebullient",
  "ecstatic",
  "elastic",
  "elated",
  "elegant",
  "epic",
  "exciting",
  "fantastic",
  "fervent",
  "festive",
  "flamboyant",
  "focused",
  "friendly",
  "funny",
  "gallant",
  "gifted",
  "goofy",
  "gracious",
  "great",
  "happy",
  "hardcore",
  "hearty",
  "helpful",
  "hopeful",
  "impassioned",
  "inspiring",
  "interesting",
  "intelligent",
  "jolly",
  "jovial",
  "keen",
  "kind",
  "laughing",
  "loving",
  "lucid",
  "magical",
  "mystifying",
  "modest",
  "musing",
  "natural",
  "nice",
  "nifty",
  "nostalgic",
  "nurturing",
  "objective",
  "optimistic",
  "peaceful",
  "practical",
  "pragmatic",
  "prepared",
  "priceless",
  "quirky",
  "quiet",
  "relaxed",
  "reliable",
  "remarkable",
  "reverent",
  "seasoned",
  "sensible",
  "serene",
  "silly",
  "sincere",
  "sociable",
  "spiritual",
  "superb",
  "sweet",
  "tender",
  "trusting",
  "triumphant",
  "unruffled",
  "upbeat",
  "valuable",
  "vibrant",
  "vigorous",
  "wizardly",
  "wonderful",
  "xenial",
  "youthful",
  "zazzy",
  "zen",
];

const right = [
  "accomplishment",
  "achievement",
  "acrobat",
  "activity",
  "advice",
  "athlete",
  "beginner",
  "bicyclist",
  "challenge",
  "champion",
  "class",
  "classmate",
  "client",
  "customer",
  "determination",
  "effort",
  "energy",
  "expert",
  "expression",
  "fortitude",
  "friendship",
  "goal",
  "goalsetter",
  "gratitude",
  "guest",
  "guru",
  "habit",
  "improvement",
  "instructor",
  "jogger",
  "leader",
  "member",
  "motivator",
  "movement",
  "newbie",
  "novice",
  "participant",
  "perserverence",
  "powerlifter",
  "practice",
  "presence",
  "prize",
  "pro",
  "progress",
  "reward",
  "routine",
  "runner",
  "session",
  "spirit",
  "skill",
  "student",
  "swimmer",
  "success",
  "teammate",
  "trainer",
  "triathlete",
  "trend",
  "trendsetter",
  "weightlifter",
  "workout",
  "victory",
  "yogi",
];

export default function () {
  const l = Math.floor(Math.random() * left.length);
  const r = Math.floor(Math.random() * right.length);
  const first = left[l].charAt(0).toUpperCase() + left[l].slice(1);
  const last = right[r].charAt(0).toUpperCase() + right[r].slice(1);
  return `${first} ${last}`;
}
