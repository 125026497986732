import Vue from "vue";
import { StripePlugin } from "@vue-stripe/vue-stripe";

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  apiVersion: "2020-08-27",
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
};

Vue.use(StripePlugin, options);
