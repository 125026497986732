import Vue from "vue";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    title: null,
    range: null,
    template: null,
    weeks: {},
    isPublic: false,
    holidays: [],
  },
  getters: {
    title(state) {
      return state.title;
    },
    range(state) {
      return state.range;
    },
    template(state) {
      return state.template;
    },
    weeks(state) {
      return state.weeks;
    },
    isPublic(state) {
      return state.isPublic;
    },
    holidays(state) {
      return state.holidays;
    },
    totalWeeks(state) {
      const range = state.range;
      if (!range) return 0;
      return (
        moment(range.end).diff(moment(range.beg).startOf("isoWeek"), "weeks") +
        1
      );
    },
  },
  mutations: {
    setTitle(state, title) {
      state.title = title;
    },
    setRange(state, range) {
      state.range = range;
    },
    setHolidays(state, holidays) {
      state.holidays = holidays;
    },
    setTemplate(state, template = null) {
      state.template = template;
    },
    updateEvent(state, { payload, id }) {
      Vue.set(state.template, id, payload);
    },
    deleteEvent(state, id) {
      Vue.delete(state.template, id);
    },
    clearWeeks(state) {
      state.weeks = {};
    },
    setWeek(state, { week, index }) {
      Vue.set(state.weeks, index, week);
    },
    setOccurrence(state, { data, id, index }) {
      Vue.set(state.weeks[index], id, data);
    },
    unsetOccurrence(state, { id, index }) {
      Vue.delete(state.weeks[index], id);
    },
    setPublic(state, isPublic) {
      state.isPublic = isPublic;
    },
  },
  actions: {
    setData({ commit }, draft) {
      commit("setPublic", !!draft?.isPublic);
      commit("setTitle", draft?.title);
      commit("setTemplate", draft?.template);
      commit("setRange", null);
      commit("setHolidays", []);
      commit("clearWeeks");
    },
    setPublic({ commit }, isPublic) {
      commit("setPublic", isPublic);
    },
    setTitle({ commit }, title) {
      commit("setTitle", title);
    },
    setRange({ commit }, range) {
      commit("setRange", range);
      commit("setHolidays", []);
      commit("clearWeeks");
    },
    setHolidays({ commit }, holidays) {
      commit("setHolidays", holidays);
    },
    setTemplate({ commit }, template) {
      commit("setTemplate", template);
    },
    updateEvent({ commit }, { payload, id }) {
      commit("updateEvent", { payload, id });
    },
    deleteEvent({ commit }, id) {
      commit("deleteEvent", id);
    },
    setWeek({ commit }, { week, index }) {
      commit("setWeek", { week, index });
    },
    setOccurrence({ commit }, { data, id, index }) {
      commit("setOccurrence", { data, id, index });
    },
    unsetOccurrence({ commit }, { id, index }) {
      commit("unsetOccurrence", { id, index });
    },
  },
};
