export default {
  namespaced: true,
  state: {
    data: {},
  },
  getters: {
    company(state) {
      return state.data.company;
    },
    logo(state) {
      return state.data.logo;
    },
    termsOfService(state) {
      return state.data.termsOfService;
    },
    privacyPolicy(state) {
      return state.data.privacyPolicy;
    },
    disclaimer(state) {
      return state.data.disclaimer;
    },
    doNotSellMyInfo(state) {
      return state.data.doNotSellMyInfo;
    },
    supportEmail(state) {
      return state.data.supportEmail;
    },
  },
  mutations: {
    setData(state, value) {
      state.data = value;
    },
  },
  actions: {
    setData({ commit }, value) {
      commit("setData", value ?? {});
    },
  },
};
