import { loadDraftFromLocalStorage } from "./utils";

export default {
  path: "/",
  redirect: { name: "local" },
  component: () => import("@/views/Home"),
  meta: {
    skipWithAuth: true,
  },
  children: [
    {
      path: "0",
      name: "local",
      redirect: { name: "local/drafts/get?view=times" },
      component: () => import("@/views/DraftLocal"),
      props: () => ({
        draft: loadDraftFromLocalStorage(),
      }),
      children: [
        {
          path: "times",
          name: "local/drafts/get?view=times",
          component: () => import("@/views/TimesDraft"),
          props: {
            minBoxWidth: 80,
            minBoxHeight: 40,
          },
          meta: {
            view: "times",
          },
        },
        {
          path: "staff",
          name: "local/drafts/get?view=staff",
          component: () => import("@/views/StaffDraft"),
          props: {
            withRange: false,
            minBoxWidth: 80,
            minBoxHeight: 60,
            maxBoxHeight: 80,
          },
          meta: {
            view: "staff",
          },
        },
      ],
    },
  ],
};
