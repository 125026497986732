import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import draft from "./modules/draft";
import config from "./modules/config";
import alert from "./modules/alert";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    user,
    draft,
    config,
    alert,
  },
});
