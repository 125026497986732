import { analytics, auth, firestore, storage } from "./app";
import Vue from "vue";

import { logEvent } from "@firebase/analytics";

import {
  GoogleAuthProvider,
  ProviderId,
  isSignInWithEmailLink,
  linkWithRedirect,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithRedirect,
  signOut,
  unlink,
  updateEmail,
  updateProfile,
} from "@firebase/auth";

import {
  addDoc,
  collection,
  doc,
  deleteDoc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
  refEqual,
  writeBatch,
} from "@firebase/firestore";

import {
  StringFormat,
  getDownloadURL,
  ref,
  uploadString,
} from "@firebase/storage";

let V; // bind on install

export class VueFirebase {
  constructor() {
    // Auto install if it is not done yet and `window` has `Vue`.  To allow
    // users to avoid auto-installation in some cases, this code should be
    // placed here.
    if (!V && typeof window !== "undefined" && window.Vue) {
      install(window.Vue);
    }
  }

  /* firebase auth */

  get currentUser() {
    return auth.currentUser;
  }

  isSignInWithEmailLink(url) {
    return isSignInWithEmailLink(auth, url);
  }

  linkWithGoogleRedirect() {
    return linkWithRedirect(this.currentUser, new GoogleAuthProvider());
  }

  onAuthStateChanged(nextFn, errFn, completeFn) {
    return onAuthStateChanged(auth, nextFn, errFn, completeFn);
  }

  sendSignInLinkToEmail(email, path) {
    return sendSignInLinkToEmail(auth, email, {
      url: window.location.origin + path,
      handleCodeInApp: true,
    });
  }

  signInWithEmailLink(email, emailLink) {
    return signInWithEmailLink(auth, email, emailLink);
  }

  signInWithGoogleRedirect() {
    return signInWithRedirect(auth, new GoogleAuthProvider());
  }

  signOut() {
    return signOut(auth);
  }

  unlinkGoogleProvider() {
    return unlink(this.currentUser, ProviderId.GOOGLE);
  }

  updateEmail(newEmail) {
    return updateEmail(this.currentUser, newEmail);
  }

  updateProfile(profile) {
    return updateProfile(this.currentUser, profile);
  }

  /* firebase storage */

  ref(path) {
    return ref(storage, path);
  }

  getDownloadURL(ref) {
    return getDownloadURL(ref);
  }

  uploadFromDataURL(ref, value) {
    return uploadString(ref, value, StringFormat.DATA_URL);
  }

  /* firestore */

  addDoc(ref, data) {
    return addDoc(ref, data);
  }

  batch() {
    return writeBatch(firestore);
  }

  collection(ref, path, ...pathSegments) {
    return collection(ref, path, ...pathSegments);
  }

  doc(ref, path, ...pathSegments) {
    return doc(ref, path, ...pathSegments);
  }

  deleteDoc(ref) {
    return deleteDoc(ref);
  }

  getDoc(ref) {
    return getDoc(ref);
  }

  getDocs(query) {
    return getDocs(query);
  }

  refEqual(left, right) {
    return refEqual(left, right);
  }

  setDoc(ref, data, options) {
    return setDoc(ref, data, options);
  }

  onSnapshot(ref, nextFn, errFn, completeFn) {
    return onSnapshot(ref, nextFn, errFn, completeFn);
  }

  query(q, ...constraints) {
    return query(q, ...constraints);
  }

  /* analytics */
  log(event, params) {
    return logEvent(analytics, event, params);
  }
}

function install(_Vue) {
  /* istanbul ignore if */
  if (
    process.env.NODE_ENV !== "production" &&
    install.installed &&
    _Vue === V
  ) {
    console.warn("firebase plugin already installed");
    return;
  }
  install.installed = true;
  V = _Vue;

  V.mixin({
    beforeCreate: vueFirebaseInit,
  });
}

function vueFirebaseInit() {
  const options = this.$options;
  if (options.firebase) {
    this.$firebase =
      typeof options.firebase === "function"
        ? options.firebase()
        : options.firebase;
  } else if (options.parent && options.parent.$firebase) {
    this.$firebase = options.parent.$firebase;
  }
}

VueFirebase.install = install;
VueFirebase.version = "__VERSION__";

Vue.use(VueFirebase);
export default new VueFirebase();
