import Vue from "vue";
import VueRouter from "vue-router";

import { auth as fb_auth, analytics as fb_analytics } from "@/firebase/app";

import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  getRedirectResult,
  reauthenticateWithCredential,
  onAuthStateChanged,
  EmailAuthProvider,
  GoogleAuthProvider,
  OperationType,
  ProviderId,
} from "@firebase/auth";
import { logEvent } from "@firebase/analytics";

import me from "./me";
import local from "./local";
import auth from "./auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter(to, from, next) {
      if (fb_auth.currentUser) {
        return next({ name: "user" });
      } else {
        return next({ name: "local" });
      }
    },
  },
  local,
  me,
  auth,
  {
    path: "*",
    name: "404",
    component: () => import("@/views/404"),
    meta: {
      title: "Not found - Take Initiative",
    },
  },
  {
    path: "*",
    name: "500",
    component: () => import("@/views/500"),
    meta: {
      title: "Internal server error - Take Initiative",
    },
  },
  {
    path: "*",
    name: "ConfirmEmail",
    component: () => import("@/views/Auth"),
    meta: {
      title: "Confirm email address - Take Initiative",
      bypass: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let u = () => {};
router.beforeEach((to, from, next) => {
  u();

  if (to.meta.bypass) {
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    return next();
  }

  let attemptedLogin = false;
  u = onAuthStateChanged(fb_auth, async (user) => {
    // check login link
    const href = window.location.origin + to.fullPath;
    if (!attemptedLogin && isSignInWithEmailLink(fb_auth, href)) {
      attemptedLogin = true;
      try {
        if (!user) {
          const emailForSignIn = window.localStorage?.getItem(
            "user/emailForSignIn"
          );
          if (!emailForSignIn) {
            return next({
              name: "ConfirmEmail",
              params: [to.path],
              query: to.query,
            });
          }
          await signInWithEmailLink(fb_auth, emailForSignIn, href);
          window.localStorage?.removeItem("user/emailForSignIn");
          return;
        } else {
          const cred = EmailAuthProvider.credentialWithLink(
            user.email,
            window.location.href
          );
          await reauthenticateWithCredential(user, cred);
        }
      } catch (err) {
        next({ name: "500", params: [to.path], query: to.query });
        throw err;
      }
    }

    // check provider redirect
    const result = await getRedirectResult(fb_auth);
    if (result?.operationType === OperationType.REAUTHENTICATE) {
      let cred;
      switch (result.providerId) {
        case ProviderId.GOOGLE:
          cred = GoogleAuthProvider.credentialFromResult(result);
          break;
      }
      await reauthenticateWithCredential(user, cred);
    }

    // handle route permission
    for (let record = to.matched.at(-1); record; record = record.parent) {
      if (user && record.meta.skipWithAuth) {
        return next({ name: "Home" });
      }

      if (!user && record.meta.authRequired) {
        return next({ name: "SignOut" });
      }
    }
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    return next();
  });
});

router.afterEach((to, from, failure) => {
  if (failure) {
    logEvent(fb_analytics, "error", {
      page_location: to.fullPath,
      page_path: to.path,
      err: failure,
    });
  } else {
    logEvent(fb_analytics, "page_view", {
      page_location: to.fullPath,
      page_path: to.path,
      page_title: document.title,
    });
  }
});

export default router;
