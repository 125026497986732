export default {
  en: {
    dayOfTheWeek: {
      weekday: "short",
    },
    dayOfTheMonth: {
      day: "numeric",
    },
    monthYear: {
      month: "long",
      year: "numeric",
    },
    monthYearShort: {
      month: "short",
      year: "numeric",
    },
    monthShort: {
      month: "short",
    },
  },
};
