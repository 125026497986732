import getRandomName from "@/namesgenerator";

export function onNavigation(f) {
  return async (to, from, next) => {
    try {
      return await f(to, from, next);
    } catch (err) {
      next({ name: "500", params: [to.path] });
      throw err;
    }
  };
}

export function loadDraftFromLocalStorage() {
  let title = window.localStorage?.getItem("draft/title");
  if (!title) {
    title = getRandomName();
    window.localStorage?.setItem("draft/title", title);
  }
  const template = window.localStorage.getItem("draft/template");
  return { title, template: template ? JSON.parse(template) : {} };
}
